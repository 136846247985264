<div *ngIf="loading; else loadedContent">
  <!-- Loading indicator -->
  <div>Loading...</div>
</div>

<ng-template #loadedContent>
  <div fxLayout="row">
    <div fxFlex="45">
      <h5>Work location</h5>
      <div fxLayout="row">
        <div fxFlex="30">
          <div style="width: 100%;" class="custom-input disabled-input-field">
            <mat-label>Location Code</mat-label>
            <textarea #locationCode matInput cdkTextareaAutosize
              value="{{ this.intakeValues.locationCode }}" #autosize="cdkTextareaAutosize" rows="3"
              cdkAutosizeMaxRows="3" placeholder="Location Code" [disabled]="true"></textarea>
          </div>
        </div>
        <div fxFlex="5">&nbsp;</div>
        <div fxFlex="65" fxLayoutAlign="end center">
          <div class="w-100 custom-input disabled-input-field">
            <mat-label>Address</mat-label>
            <textarea #locationAddress matInput cdkTextareaAutosize
              value="{{ this.intakeValues.locationFullAdddress }}" #autosize="cdkTextareaAutosize" rows="3"
              cdkAutosizeMaxRows="3" placeholder="Full Address" [disabled]="true"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="5"></div>
    <div fxFlex="49">
      <h5>{{this.dynamicLabel.labelEmployee}} Home Address</h5>
      <div class="w-100 custom-input disabled-input-field">
          <mat-label>Address</mat-label>
          <textarea #empHomeAddress matInput cdkTextareaAutosize
            value="{{ this.intakeValues.empFullHomeAddress }}" #autosize="cdkTextareaAutosize" rows="3"
            cdkAutosizeMaxRows="3" placeholder="Employee Home Address" [disabled]="true"></textarea>
        </div>
      </div>
  </div>
  
  <div fxLayout="row" [formGroup]="this.formGroup">
    <div fxFlex="45">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="isProviderExpanded$ | ngrxPush">
          <mat-expansion-panel-header>
            <mat-panel-title class="title h5-title">
              Provider list
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="apiCallNeeded; else noRecords">
            <div *ngIf="providers$ | async as providers; else loading">
              <div *ngIf="providers.length > 0; else noRecords">
                <div class="panel-content custom-table-wrapper">
                  <div *ngFor="let provider of (providers$ | async)" class="rows">
                    <div fxLayout="column" fxLayouAlign="start center">
                      <div class="title">{{provider.providerName}}</div>
                      <div>{{provider.fullAddress}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" fxLayouAlign="flex-start">
                        <div>{{provider.phone}}</div>
                      </div>
                      <div fxFlex="50" fxLayoutAlign="end center">
                        <button mat-raised-button color="primary" class="btn-sm" [disabled]="formGroup.disabled"
                          (click)="applyProvider(provider)">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #loading>
            <div>Loading...</div>
          </ng-template>
  
          <!-- When no API call is needed, use this block -->
          <ng-template #noRecordsOrProviders>
            <div class="alert alert-danger" role="alert">No provider data available</div>
          </ng-template>
  
          <!-- When API call is made but data is empty -->
          <ng-template #noRecords>
            <div class="alert alert-danger" role="alert">No provider data available</div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div fxFlex="5"></div>
    <div fxFlex="48" formGroupName="provider">
      <h5>Selected Provider Information</h5>
      <div fxLayout="row">
        <div fxFlex="35" class="space custom-input space-m-r-10" 
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'providerType'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'providerType'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'providerType'])?.disabled
         }">
          <mat-label>Provider Type</mat-label>
          <input #providerType maxlength="50" matInput formControlName="providerType" placeholder="Provider type" />
          <mat-hint *ngIf="providerType.value?.length > 25">{{ providerType.value?.length || 0 }}/50</mat-hint>
        </div>
        <div fxFlex="25" class="space custom-input space-m-r-10"
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'providerRanking'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'providerRanking'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'providerRanking'])?.disabled
         }">
          <mat-label>Ranking</mat-label>
          <input #providerRanking minlength="1" maxlength="9999" type="number" matInput formControlName="providerRanking"
            placeholder="Ranking" />
          <mat-hint *ngIf="showMaxLengthValidation" class="invalid-value">
            <p>This value should be between 1 to 9999</p>
          </mat-hint>
        </div>
        <div fxFlex="35" class="custom-input"
        [ngClass]="{ 'disabled-input-field': providerSearchForm.get(['provider', 'hoursOperations'])?.disabled }">
          <mat-label>Hours Operations</mat-label>
          <input #hoursOperations maxlength="50" matInput formControlName="hoursOperations"
            placeholder="Hours Operations" />
          <mat-hint *ngIf="hoursOperations.value?.length > 25">{{ hoursOperations.value?.length || 0
            }}/50</mat-hint>
        </div>
      </div>
      <div fxLayout="row">
        <div class="w-100 custom-input"
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'facilityName'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'facilityName'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'facilityName'])?.disabled
         }">
          <mat-label>Facility Name</mat-label>
          <input #facilityName maxlength="150" matInput formControlName="facilityName" placeholder="Facility Name" />
          <mat-hint *ngIf="facilityName.value?.length > 75">{{ facilityName.value?.length || 0 }}/150</mat-hint>
        </div>
      </div>
      <div fxLayout="row">
        <div class="w-100 custom-input" fxFlex="100"
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'providerName'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'providerName'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'providerName'])?.disabled
         }">
          <mat-label>Provider Name</mat-label>
          <input #providerName maxlength="150" matInput formControlName="providerName" placeholder="Provider Name" />
          <mat-hint *ngIf="providerName.value?.length > 75">{{ providerName.value?.length || 0 }}/150</mat-hint>
        </div>
      </div>
      <div fxLayout="row">
        <div class="w-100 custom-input" fxFlex="100"
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'providerAddress'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'providerAddress'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'providerAddress'])?.disabled
         }">
          <mat-label>Address</mat-label>
          <input #providerAddress maxlength="200" matInput formControlName="providerAddress"
            placeholder="provider Address" />
          <mat-hint *ngIf="providerAddress.value?.length > 100">{{ providerAddress.value?.length || 0
            }}/200</mat-hint>
        </div>
      </div>
      <div fxLayout="row">
        <div class="w-50 space custom-input space-m-r-10"
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'providerPhone'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'providerPhone'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'providerPhone'])?.disabled
         }">
          <mat-label>Phone</mat-label>
          <input id="phone-number" matInput #providerPhone formControlName="providerPhone"
            mask="(000)-000-0000" maxlength="20">
          <mat-error *ngIf="phoneError" class="invalid-feedback">
            <p *ngIf="phoneError.minlength">
              Please enter valid Provider phone number
            </p>
          </mat-error>
        </div>
        <div class="w-50 custom-input" 
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'providerFax'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'providerFax'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'providerFax'])?.disabled
         }">
          <mat-label>Provider’s Fax</mat-label>
          <input id="phone-number" matInput #providerFax formControlName="providerFax" [ngClass]="{ 'is-invalid': faxError }"
            mask="(000)-000-0000" maxlength="20"/>
          <mat-error *ngIf="faxError" class="invalid-feedback">
            <p *ngIf="faxError.minlength">
              Please enter valid Provider fax number
            </p>
          </mat-error>
        </div>
      </div>
      <div fxLayout="row">
        <div class="w-100 custom-input" fxFlex="100"
        [ngClass]="{ 'required-input-field' : providerSearchForm.get(['provider', 'otherReferrals'])?.hasValidator(requiredValidator),
          'valid-input-field': !isInvalidField(providerSearchForm.get(['provider', 'otherReferrals'])),
          'disabled-input-field': providerSearchForm.get(['provider', 'otherReferrals'])?.disabled
         }">
          <mat-label>Other Referrals</mat-label>
          <textarea #otherReferrals maxlength="150" matInput formControlName="otherReferrals" cdkTextareaAutosize
            #autosize="cdkTextareaAutosize" rows="3" cdkAutosizeMaxRows="3"></textarea>
          <mat-hint *ngIf="otherReferrals.value?.length > 125">{{ otherReferrals.value?.length || 0 }}/150</mat-hint>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end end">
        <button mat-raised-button color="primary" size="small" matTooltip="Clear provider fields" 
          (click)="clearProvider()" [disabled]="formGroup.disabled">
          Clear Provider
        </button>
      </div>
    </div>
  </div>
  <hr>
</ng-template>

