import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'softdelete-dialog',
  template: `
    <button mat-icon-button class="close-button" mat-dialog-close="close">
      <mat-icon class="close-icon" color="black">close</mat-icon>
    </button>
    <h2 mat-dialog-title fxLayoutAlign="center center">{{ title }}</h2>
    <mat-dialog-content class="mat-typography">
      <h3>{{ message }}</h3>
    </mat-dialog-content>
    <div fxLayout="column" class="p-3" fxLayoutGap="2%">
      <mat-card-content fxFlex="80">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
          <mat-form-field fxFlex="100">
            <mat-label>Reason for Soft Delete</mat-label>
            <textarea
              #remarkSystem
              maxlength="1000"
              cols="85"
              rows="3"
              matInput
              formControlName="softDeleteRemarks"
            ></textarea>
            <mat-hint *ngIf="remarkSystem.value?.length > 1"
              >{{ remarkSystem.value?.length || 0 }}/1000</mat-hint
            >
          </mat-form-field>
        </div>
      </mat-card-content>
    </div>
    <mat-dialog-actions>
      <div
        fxLayout="row"
        fxLayoutAlign="end"
        style="text-align:center;width:100%"
      >
        <button
          mat-raised-button
          color="primary"
          mat-dialog-close="skip"
        >
          {{ cancelButtonText }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!(remarkSystem.value?.length > 0)"
          [mat-dialog-close]="remarkSystem.value"
        >
          {{ acceptButtonText }}
        </button>
      </div>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .close-button {
        float: right;
        top: -10px;
        right: -10px;
      }

      ::ng-deep .icon-outside .close-button {
        float: right;
        top: -52px;
        right: -52px;
      }

      /* ::ng-deep .icon-outside .mat-dialog-container {
 overflow: unset
} */
    `,
  ],
})
export class SoftDeleteDialogComponent {
  title: string;
  message!: string;
  isContunue: true;
  acceptButtonText = 'YES';
  cancelButtonText = 'CANCEL';
  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.title !== undefined) this.title = data.title;
    if (data.message !== undefined) this.message = data.message;
    if (data.acceptButtonText !== undefined)
      this.acceptButtonText = data.acceptButtonText;
    if (data.cancelButtonText !== undefined)
      this.cancelButtonText = data.cancelButtonText;
  }
}
