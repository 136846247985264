import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig } from '@autobot/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, forkJoin, identity, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, retry } from 'rxjs/operators';
import { Lookup, SourceType } from '../models/lookup.model';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  _sources: BehaviorSubject<Lookup[]> = new BehaviorSubject<any>([]);
  _state: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  state$ = this._state.asObservable();
  sources$ = this._sources.asObservable();
  props?: { [key: string]: any };
  apiURL = this.environment.apiConfig.formsUri + '/api/medicalprotocols';
  
  accountid: any;
  storeData: any;
  files: Lookup[] = [
    {
      id: '73cb3dc2-3bbc-4601-a1c2-d6332cd57384',
      name: 'bodyPartLocations',
      src: 'assets/data/lookups/bodyPartLocations.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: '7be57873-3613-4e65-a58b-8394dd705087',
      name: 'bodyParts',
      src: 'assets/data/lookups/bodyparts.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: '92b92e29-a436-43ff-9ff1-043e78585d41',
      name: 'dispositions',
      src:
        this.environment.apiConfig.accountsUri + '/api/lookupdata/dispositions',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: '92b92e29-a436-43ff-9ff1-043e78585d41',
      name: 'dispositionRecommended',
      src: 'assets/data/lookups/dispositionRecommended.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'a2273343-dfbf-4115-8a4c-ad5860959e37',
      name: 'escalationCriteria',
      src: 'assets/data/lookups/escalationCriteria.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'ca51a202-a81c-4733-81ab-5b5c6ce56b40',
      name: 'injuryIllnessCauses',
      src: 'assets/data/lookups/injuryIllnessCauses.json',
      type: 'value-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'ee3ffe73-3928-45cc-977d-f568d0d3d630',
      name: 'injuryIllnessNatures',
      src: 'assets/data/lookups/injuryIllnessNatures.json',
      type: 'value-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: '43d28c81-ef79-4e5d-bb86-cea85edb8e50',
      name: 'protocolList',
      src: 'assets/data/lookups/protocols.json',
      type: 'name-id',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: '43d28c81-ef79-4e5d-bb86-cea85edb8e51',
      name: 'protocols',
      src: this.apiURL,
      type: 'name-id',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: '3bfc37d7-ead2-45e5-afed-e1786cedfe68',
      name: 'states',
      src: 'assets/data/lookups/states.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'd8c61a0e-ef40-43d6-9bea-c6788e6d5dbe',
      name: 'zipCodes',
      src: 'assets/data/lookups/zipcodes.json',
      type: 'value-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: '92bd3b21-25d9-4e04-bfd9-78da46158142',
      name: 'providers',
      src: 'assets/data/fakes/fake.providers.json',
      type: 'Provider',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'a2273343-dfbf-4115-8a4c-ad5860959e58',
      name: 'whyNot',
      src:
        this.environment.apiConfig.accountsUri + '/api/lookupdata/whyNot',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: 'e995a7b8-deb9-4430-be55-7ff93b9615f3',
      name: 'whyNotwalmartsams',
      src:
      this.environment.apiConfig.accountsUri + '/api/lookupdata/whyNotwalmartsams',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: 'a2273343-dfbf-4115-8a4c-ad5860959e75',
      name: 'medicalProvider',
      src:
      this.environment.apiConfig.accountsUri + '/api/lookupdata/medicalProvider',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: 'a2273343-ya8f-4115-56wc-ch5860959a75',
      name: 'location',
      src: 'assets/data/lookups/location.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'E7D2D0E4-D7C6-4496-A69E-542659C23888',
      name: 'lagtimereason',
      src: 'assets/data/lookups/lagtimereason.json',
      type: 'value-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: '8A1DB7A7-2E5A-4AD4-9F2F-ADEA5B775F21',
      name: 'language',
      src: 'assets/data/lookups/language.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: '54D4EE90-97FD-4F0C-AE72-12FDC36B577D',
      name: 'treatmentchosenbyee',
      src: 'assets/data/lookups/treatmentchosenbyee.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'E8309440-3B9F-4B50-84B0-C753253DB0A4',
      name: 'maritalstatus',
      src: 'assets/data/lookups/maritalstatus.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'E8309440-3B9F-4B50-84B0-C759253DB0A6',
      name: 'timeFrame',
      src: 'assets/data/lookups/timeframe.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'E8309440-3B9F-4B50-84B0-C759253DB0F5',
      name: 'eedisagree',
      src: 'assets/data/lookups/eedisagree.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'G9309440-3B9F-4B50-84B0-C759253DBf80',
      name: 'caseOrigin',
      src:
        this.environment.apiConfig.accountsUri + '/api/lookupdata/caseOrigin',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: 'E8309440-3B9F-4B50-84B0-C759253DB0F5',
      name: 'caseType',
      src: this.environment.apiConfig.accountsUri + '/api/lookupdata/caseType',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: 'gg839440-3B9F-4GG50-84B0-EE75325B0A4',
      name: 'languageVendor',
      src:
        this.environment.apiConfig.accountsUri +
        '/api/lookupdata/languageVendor',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: 'A10109440-3B9F-4B50-84B0-D75195D30F5',
      name: 'followUpOutcome',
      src: 'assets/data/lookups/followUpOutcome.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'C540109440-3R9F-4B60-84B0-F75195H30F5',
      name: 'dispositionCategory',
      src: 'assets/data/lookups/dispositionCategory.json',
      type: 'label-value',
      sourceType: SourceType.file,
      updated: new Date(),
    },
    {
      id: 'a7773343-dfbf-4775-8a4c-ad7870959e78',
      name: '911_checklist',
      src:
        this.environment.apiConfig.accountsUri + '/api/lookupdata/911_checklist',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
    {
      id: 'e885a7b9-deb8-6630-be66-7ff93b9615f3',
      name: '911_checklist_walmart_sams',
      src:
      this.environment.apiConfig.accountsUri + '/api/lookupdata/911_checklist_walmart_sams',
      type: 'label-value',
      sourceType: SourceType.url,
      updated: new Date(),
    },
  ];
  baseUrl: string;
  routeParams: any;

  getFiles() {
    return this.files;
  }

  init() {
    this._sources.next(this.files);

    const sources = this._sources.getValue();
    if (sources.length == 0)
      throw new Error('Source List is empty. Please add lookup sources');

    const calls = sources.map((f) => {
      return { name: f.name, req: this.loadFile(f.name, f.src) };
    });

    return forkJoin(calls.map((c) => c.req)).pipe(
      map((result) => {
        let data = {};
        result.forEach((r, idx) => {
          let item = { [sources[idx].name]: r };
          data = { ...data, ...item };
        });
        this._state.next(data);

        return data;
      })
    );
  }

  initCustom(lookupSources?: Lookup[]) {
    if (lookupSources !== undefined) {
      this._sources.next(lookupSources);
    }

    const sources = this._sources.getValue();
    if (sources.length == 0)
      throw new Error('Source List is empty. Please add lookup sources');

    const calls = sources.map((f) => {
      return { name: f.name, req: this.loadFile(f.name, f.src) };
    });

    return forkJoin(calls.map((c) => c.req)).pipe(
      map((result) => {
        let data = {};
        result.forEach((r, idx) => {
          let item = { [sources[idx].name]: r };
          data = { ...data, ...item };
        });
        this._state.next(data);

        return data;
      })
    );
  }

  getLookUpByName(lookupName) {
    const src =
      this.environment.apiConfig.accountsUri + `/api/lookupdata/${lookupName}`;
    return this.http.get<any>(src).pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  loadFile(name: string, src: string) {
    return this.http.get(src);
  }
  filter(term: string, dataSet: string) {
    const arr = this.state$.pipe(select(dataSet));

    const result = arr.pipe(
      map((item: any[]) => {
        const options = item?.map((e) => {
          return {
            label: e.label ?? e.value,
            value: e.label ?? e.value,
          };
        });

        const result = options?.filter(
          (emp) => emp.label.toLowerCase().indexOf(term?.toLowerCase()) !== -1
        );

        return result;
      })
    );

    return result;
  }

  filterLocation(term: string, dataSet: string) {
    let result: any;

    if (term.length > 2) {
      if (!this.accountid) {
        this.store.subscribe((data) => {
          this.storeData = data;
          this.accountid = this.storeData?.session?.case?.calls[0].accountId;
        });
      }

      let searchurl =
        this.environment.apiConfig.accountsUri +
        '/api/location/' +
        this.accountid +
        '/' +
        term;
      const arr = this.http.get(searchurl).pipe(finalize(() => {}));

      result = arr.pipe(
        map((item: any[]) => {
          const options = item.map((e) => {
            return {
              label:
                e.address +
                ' ' +
                e.city +
                ' ' +
                e.state +
                ' ' +
                e.zip +
                ' ' +
                e.code,
              value: e.address,
              address: e.address,
              city: e.city,
              state: e.state,
              Zip: e.zip,
              code: e.code,
              name: e.name,
              division: e.division,
              phoneNumber: e.phoneNumber,
            };
          });

          result = options?.filter(
            (emp) => emp.label.toLowerCase().indexOf(term?.toLowerCase()) !== -1
          );

          return result;
        })
      );
    }

    return result;
  }
  searchManager(term: string, Id: string) {
    let result: any;
    if(Id){
    this.accountid=Id;
    }
    else if (!this.accountid) {
      this.store.subscribe((data) => {
        this.storeData = data;
        this.accountid = this.storeData?.session?.case?.calls[0].accountId;
      });
    }
    if (term.length > 2) {
      
      let searchurl =
        this.environment.apiConfig.accountsUri +
        '/api/storemanager/search?searchText=' +
        term +'&accountId='+this.accountid;
      const arr = this.http.get(searchurl).pipe(finalize(() => {}));

      result = arr.pipe(
        map((item: any[]) => {
          const options = item.map((e) => {
            return {
              label:
              e.location.code+' '+e.firstName+' '+e.lastName+' '
               +e.location.address+' '+e.location.city+' '+e.location.state+ ' '+e.location.zip,
              value: e.location.address,
              firstName: e.firstName,
              lastName: e.lastName,
              jobTitle: e.jobTitle,
              email:e.email,
              phoneNumber: e.phoneNumber,
              location: e.location

            };
          });

          result = options?.filter(
            (emp) => emp.label.toLowerCase().indexOf(term?.toLowerCase()) !== -1
          );

          return result;
        })
      );
    }

    return result;
  }
  getItemByfieldID(hint: string, fieldValue: string) {
    const arr = this.state$.pipe(select(hint));

    const result = arr.pipe(
      map((item: any[]) => {
        const options = item.map((e) => {
          return {
            label: e.label ?? e.value,
            value: e.value ?? e.label,
          };
        });

        const result = options?.filter((emp) => emp.value == fieldValue);

        return result;
      })
    );
    return result;
  }
  mapOptions(hint: string, dict: any) {
    const foundArr = dict[hint];

    const result = foundArr
      ? dict[hint].map((e: any) => {
          return {
            label: e.label ?? e.value,
            value: e.label ?? e.value,
          };
        })
      : [];

    return result;
  }
  dataFilter(term: string, dataSet: string) {
    const arr = this.state$.pipe(select(dataSet));

    const result = arr.pipe(
      map((options: { label: string; value: any }[]) => {
        const result = options.filter(
          (emp) => emp.label.toLowerCase().indexOf(term?.toLowerCase()) !== -1
        );

        return result;
      })
    );

    return result;
  }

  getAccountConfigByAccountIdKeyName(keyname: string, accountid: any) {
    let result: any;

    let url =
      this.environment.apiConfig.accountsUri +
      '/api/config/' +
      accountid +
      '/' +
      keyname;

    return this.http.get<any>(url).pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  getAccountId(){
    if(!this.accountid){
      this.store.subscribe((data) => {
        this.storeData = data;
        console.log(data);
        this.accountid = this.storeData?.session?.case?.accountId;
      });
    }

    return this.accountid;
  }

  getPhysicalTherapyLookupData(): { stateOfJurisdiction: string; accountProps: any } {
    let stateOfJurisdiction = '';
    let accountProps: any = null;
  
    this.store.select((state) => state).subscribe((storeData) => {
      const accountId = storeData['session']?.case?.accountId;
      stateOfJurisdiction =
        storeData['session']?.case?.model?.intake?.accident?.details
          ?.stateOfJurisdiction || '';
  
      const selectedAccount = storeData['accounts']?.accounts?.entities?.[accountId];
      const parentAccountId = selectedAccount?.parentId;
      accountProps = storeData['accounts']?.accounts?.entities?.[parentAccountId]?.props || null;
    });
  
    return { stateOfJurisdiction, accountProps };
  }

  getSelectedAccountProps(): {  accountProps: any } {
   
    let accountProps: any = null;
  
    this.store.select((state) => state).subscribe((storeData) => {
      const accountId = storeData['session']?.case?.accountId;
      accountProps = storeData['accounts']?.accounts?.entities?.[accountId]?.props || null;
    });
  
    return {  accountProps };
  }
  

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      msg = error.error.message;
    } else {
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {}
}
