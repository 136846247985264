import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import {
  updateCaseModelCustom,
  updateTriageProtocolModel,
  updateValidCaseFormList,
} from '../actions/case.actions';
import {
  exitPage,
  loadFormFromCustomPage,
  loadProtocol,
  validateRedirectToLink,
} from '../actions/custom-form.action';

import { IForm } from '@forms/common';
import { CommonFunctionService } from '@nfr/common';
import {
  getSubjectiveAssmtFormlyFields,
  getSubjectiveAssmtIForm,
  getfollowUpFormlyFields,
  getfollowUpIForm,
  selectEnabledChildMenu,
  selectModel,
} from '../selectors';

@Injectable({ providedIn: 'root' })
export class CustomFormFacade {
  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private commonFunction: CommonFunctionService
  ) {}

  //#region  custom forms
  form: FormGroup = new FormGroup({});
  secondaryGaugeData = [];

  secAssmtForm = this.formBuilder.group({
    name: ['SecAssmt', null],
    secAssmtProtocols: this.formBuilder.array([]),
  });
  primaryAssmtForm = this.formBuilder.group({
    name: ['PrimaryAssmt', null],
    primaryAssmtProtocols: this.formBuilder.array([]),
  });
  interventionForm = this.formBuilder.group({
    name: ['intervention', null],
    interventionProtocols: this.formBuilder.array([]),
  });

  provider = this.formBuilder.group({
    providerInformation: ['', [Validators.required]],
    fax: ['', [Validators.required, Validators.minLength(10)]],
    otherReferrals: [''],
    providerSelected: ['', [Validators.required]],
    whyNot: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    otherReason: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    agreementInformation: ['', [Validators.required]],
    medicalProvider: ['', [Validators.required]],
    startTime: [new Date()],
  });

  //nurse tirage form
  nursetriage = this.formBuilder.group({
    selfCareInstructions: [''],
    email: new FormControl('', [Validators.email]),
    isVerbalized: [''],
    isAgreesToComply: [''],
    isComfortable: [''],
    isAnticipatedCompliance: [''],
    isAllQuestionsAnswered: [''],
    acknowledgepoc: [''],
  });

  //#endregion
  disposition = this.formBuilder.group({
    nursingConclusions: ['', [Validators.required]],
    isTetanus: ['', [Validators.required]],
    isDrugTested: ['', [Validators.required]],
    dispositionRecommended: ['', [Validators.required]],
    includePhysicalTherapy: [''],
    directEmployee: ['', [Validators.required]],
    isEEAgrees: ['', [Validators.required]],
    eeDisagrees: ['', [Validators.required]],
    ifKnowWhy: ['', [Validators.required]],
    typeOfTreatment: ['', [Validators.required]],
    isEEAdvised: [''],
  });

  //#region Triage

  model$: Observable<any> = this.store.select(selectModel);
  //#endregion

  //#region Subjective Assessment
  subjectiveAssmtiForm$: Observable<IForm> = this.store.select(
    getSubjectiveAssmtIForm
  );

  subjectiveAssmtFields$: Observable<FormlyFieldConfig[]> = this.store.select(
    getSubjectiveAssmtFormlyFields
  );
  followUpiForm$: Observable<IForm> = this.store.select(getfollowUpIForm);
  followUpFields$: Observable<FormlyFieldConfig[]> = this.store.select(
    getfollowUpFormlyFields
  );
  isChildMenuEnabled$(subMenuLink) {
    return this.store.select(selectEnabledChildMenu(subMenuLink));
  }
  loadSubjectiveAssmtForm(templateKey: string): void {
    this.store.dispatch(loadFormFromCustomPage({ templateKey }));
  }
  loadFollowUpForm(templateKey: string): void {
    this.store.dispatch(loadFormFromCustomPage({ templateKey }));
  }

  //#endregion

  //#region  Protocols
  loadProtocolForm(protocolTemplateIds: string[], protocolType: string): void {
    this.store.dispatch(
      loadProtocol({ templateIds: protocolTemplateIds, protocolType })
    );
  }
  //#endregion

  updateModel(data: any) {
    this.store.dispatch(updateCaseModelCustom({ data }));
  }
  updateTriageProtocolModel(data: any) {
    this.store.dispatch(updateTriageProtocolModel({ data }));
  }
  updateTriageData(triagemodel: any, currentFormModel) {
    const merged = this.commonFunction.deepMergeObject(
      triagemodel,
      currentFormModel
    );
    this.updateTriageProtocolModel(merged);
  }

  navigateTo(selectedMenu: any, relativeTo: any) {
    return this.store.dispatch(
      validateRedirectToLink({ selectedMenu, relativeTo })
    );
  }
  clearForm() {
    this.form = new FormGroup({});
  }
  exitEditor() {
    this.store.dispatch(exitPage());
  }

  clearAndExitForm() {
    this.store.dispatch(exitPage());
    this.form = new FormGroup({});
  }
  updateValidCaseFormList(model: any) {
    this.store.dispatch(updateValidCaseFormList({ data: model }));
  }
}
