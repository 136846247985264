import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectIsLoggedIn,
  selectUserProfile,
  selectUserRoles,
  selectIsSupervisor,
  selectHasNurseOnly
} from '../store/authentication.selectors';
import { AuthState } from '../store/authentication.reducer';
import { checkAuth, login, logout } from '../store/authentication.actions';

@Injectable({ providedIn: 'root' })
export class AuthenticationFacade {
  loggedIn$: Observable<boolean> = this.store.select(selectIsLoggedIn);
  isSupervisor$: Observable<boolean> = this.store.select(selectIsSupervisor);
  profile$: Observable<any> = this.store.select(selectUserProfile);
  roles$: Observable<string[]> = this.store.select(selectUserRoles);
  isNurseOnly$: Observable<boolean> = this.store.select(selectHasNurseOnly);
  constructor(private store: Store<AuthState>) {
    this.store.dispatch(checkAuth());
  }

  login() {
    this.store.dispatch(login());
  }
  logout() {
    this.store.dispatch(logout());
  }
}

export abstract class AuthenticationFacadeBase {
  abstract loggedIn$: Observable<boolean>;
  abstract profile$: Observable<any>;
  abstract roles$: Observable<string[]>;
  abstract login(): void;
  abstract logout(): void;
}
