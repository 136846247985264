import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';

@Component({
  selector: 'confirm-reopencase-dialog',
  template: `
    <button mat-icon-button class="close-button" mat-dialog-close="close">
      <mat-icon class="close-icon" color="black">close</mat-icon>
    </button>
    <h2 mat-dialog-title fxLayoutAlign="center center">{{ title }}</h2>

    <mat-dialog-content class="mat-typography">
      {{ message }}
    </mat-dialog-content>

    <div fxLayout="column" class="p-3" fxLayoutGap="2%">
      <mat-card-content fxFlex="80">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
          <div class="custom-input" fxFlex="100">
            <mat-label>Reasons for Reopening the case</mat-label>
            <textarea
              #ReopenCaseInformation
              maxlength="1000"
              cols="85"
              rows="3"
              matInput
              formControlName="ReopenCaseInfo"
            ></textarea>
            <mat-hint *ngIf="ReopenCaseInformation.value?.length > 1"
              >{{ ReopenCaseInformation.value?.length || 0 }}/1000</mat-hint
            >
          </div>
        </div>
      </mat-card-content>
    </div>
    <mat-dialog-actions>
      <div
        fxLayout="row"
        fxLayoutAlign="end"
        style="text-align:right;width:100%"
      >
        <button
          mat-raised-button
          color="primary"
          [disabled]="!(ReopenCaseInformation.value?.length === 0)"
          mat-dialog-close="skip"
          (click)="onCloseClick()"
          tabindex="-1"
        >
          {{ cancelButtonText }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!(ReopenCaseInformation.value?.length > 0)"
          [mat-dialog-close]="ReopenCaseInformation.value"
        >
          {{ acceptButtonText }}
        </button>
        <!--<p>ReopenCaseInformation Length:{{ReopenCaseInformation.value?.length}}</p>-->
      </div>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .close-button {
        float: right;
        top: -10px;
        right: -10px;
      }

      ::ng-deep .icon-outside .close-button {
        float: right;
        top: -52px;
        right: -52px;
      }

      /* ::ng-deep .icon-outside .mat-dialog-container {
 overflow: unset
} */
    `,
  ],
})
export class ConfirmReopenCaseDialogComponent {
  title: string;
  message!: string;
  isContunue: true;
  acceptButtonText = 'YES';
  cancelButtonText = 'CANCEL';
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ConfirmReopenCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.title !== undefined) this.title = data.title;
    if (data.message !== undefined) this.message = data.message;
    if (data.acceptButtonText !== undefined)
      this.acceptButtonText = data.acceptButtonText;
    if (data.cancelButtonText !== undefined)
      this.cancelButtonText = data.cancelButtonText;
  }
  onCloseClick() {
    this.dialogRef.close();
  }
}
