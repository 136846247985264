import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  template: `
   <button mat-icon-button class="close-button" [mat-dialog-close]="false"  *ngIf="showCloseButton">
    <mat-icon class="close-icon" color="black">close</mat-icon>
  </button>
    <h2 mat-dialog-title *ngIf="title">{{ title }}</h2>
    <mat-dialog-content class="mat-typography">
    <div>
      <mat-icon *ngIf="data.showIcon">info</mat-icon>
      <span [innerHTML]="message">{{ message }}</span> 
    </div>     
    </mat-dialog-content>
    <div mat-dialog-actions fxLayoutAlign="end center">
      <button
        mat-raised-button
        *ngIf="showCancelButton"
        [mat-dialog-close]="false"
        cdkFocusInitial
      >
        {{ cancelButtonText }}
      </button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">
        {{ acceptButtonText }}
      </button>
    </div>
  `,
  styles: [`.close-button {
        float: right;
        top: -10px;
        right: -10px;
      }

      ::ng-deep .icon-outside .close-button {
        float: right;
        top: -52px;
        right: -52px;
      }

      /* ::ng-deep .icon-outside .mat-dialog-container {
 overflow: unset
} */`],
})
export class ConfirmDialogComponent {
  showCancelButton = true;
  showCloseButton = false;
  showIcon = false;
  title!: string;
  message!: string;
  acceptButtonText!: string;
  cancelButtonText!: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.title !== undefined) this.title = data.title;
    if (data.message !== undefined) this.message = data.message;
    if (data.acceptButtonText !== undefined)
      this.acceptButtonText = data.acceptButtonText;
    if (data.cancelButtonText !== undefined)
      this.cancelButtonText = data.cancelButtonText;
    if (data.showCancelButton !== undefined)
      this.showCancelButton = data.showCancelButton;
    if (data.showCloseButton !== undefined)
      this.showCloseButton = data.showCloseButton;
    if (data.showIcon !== undefined) this.showIcon = data.showIcon;
  }
}
