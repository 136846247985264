import * as _ from 'lodash-es';

export interface Account {
  id: string;
  accountType: string;
  shortName: string;
  name: string;
  address?: string;
  tin?: string;
  phoneNumber?: string;
  startDate?: Date;
  endDate?: Date;
  skillNumber?: string;
  isDataIsolated?: boolean;
  isolationMode?: string;
  tenant?: string;
  parentId?: string;
  parentName?: string;
  props?: { [key: string]: any };
  locations?: Location[];
  createdBy?: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  assemblyId: string;
  active: boolean;
  configs: { [key: string]: any };
  accountFeature?: AccountFeature[];
}

export interface AccountActivation {
  id: string;
  active: boolean;
  updatedBy: string;
  startDate?: Date;
  endDate?: Date;
}

export interface AccountFeature {
  endDate?: Date;
  FeatureId: number;
  startDate?: Date;
  featureName?: string;
  featureDescription?: string;
  featureProps?: string;
}

export interface AccountExpandedView {
  id: string;
  accountType: string;
  shortName: string;
  name: string;
  address: string;
  tin?: string;
  phoneNumber?: string;
  startDate?: Date;
  endDate?: Date;
  skillNumber?: string;
  isDataIsolated?: boolean;
  tenant: string;
  parentId?: string;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  props?: { [key: string]: any };
  locations?: Location[];
  parentAccount?: Account;
  children: Account[];
}

export interface AccountSearchResult {
  id: string;
  accountType: string;
  name: string;
  displayName: string; // hyphen delimited?
  hierarchy: string[];
  tenant: string; //default or tenant
  shortName: string;
  skillNumber: string;
  parentId?: string;
}

export interface AccountView {
  id: string;
  accountType: string;
  shortName: string;
  name: string;
  address: string;
  tin?: string;
  phoneNumber?: string;
  startDate?: Date;
  endDate?: Date;
  skillNumber?: string;
  isDataIsolated?: boolean;
  tenant: string;
  parentId?: string;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  props?: { [key: string]: any };
  locations?: Location[];
  parentAccount?: Account;
  accounts: AccountView[];
}
export interface AccountListView {
  id: string;
  accountType: string;
  shortName: string;
  name: string;
  address: string;
  tin?: string;
  phoneNumber?: string;
  startDate?: Date;
  endDate?: Date;
  skillNumber?: string;
  tenant: string;
  updated: Date;
  parentAccount?: AccountListView;
}
export interface AddAccountRequest {
  id: string;
  accountType: string;
  shortName: string;
  name: string;
  address: string;
  tin?: string;
  phoneNumber?: string;
  startDate?: Date;
  endDate?: Date;
  skillNumber?: string;
  isDataIsolated?: true;
  tenant: string;
  props?: { [key: string]: any };
  createdBy: string;
}
export interface Location {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
export interface AccountMinimalView {
  id: string;
  accountType: string;
  name: string;
  displayName?: string;
  shortName: string;
  tenant?: string;
  skillNumber?: string;
  parentId?: string;
  tin?: string;
  props?: { [key: string]: any };
  featureNames?: string[];
}

export interface AccountDetailView {
  id: string;
  accountType: string;
  name: string;
  displayName?: string;
  shortName: string;
  tenant?: string;
  skillNumber?: string;
  phoneNumber?: string;
  parentId?: string;
  tin?: string;
  props?: { [key: string]: any };
  configs: { [key: string]: any };
}

export interface Config {
  accountId: string;
  active: boolean;
  keyName: string;
  module: string;
  type: string;
  value: [string];
}
export interface UpdateConfig {
  accountId: string;
  active: boolean;
  keyName: string;
  value: string;
}
export function AccountViewMapper(
  accounts: Account[],
  level?: string
): Partial<AccountExpandedView>[] {
  const t = accounts;
  const _contracts = _.filter(t, (c) => c.accountType == 'contract');
  const _accounts = _.filter(t, (c) => c.accountType == 'account');
  const _subs = _.filter(t, (c) => c.accountType == 'sub');

  if (level == 'contract' || !level) {
    const result = _contracts.map((contract) => {
      const accounts = _accounts
        .filter((a) => a.parentId == contract.id)
        .map((account) => {
          const subs = _subs
            .filter((sa) => sa.parentId == account.id)
            .map((sub) => {
              return {
                ...sub,
                parentAccount: account,
              };
            });

          return {
            ...account,
            parentAccount: contract,
            accounts: subs,
          };
        });
      return {
        ...contract,
        accounts,
      };
    });
    return result;
  }
  if (level == 'account') {
    const result = _accounts.map((account) => {
      const subs = _subs
        .filter((sa) => sa.parentId == account.id)
        .map((sub) => {
          return {
            ...sub,
            parentAccount: account,
          };
        });
      const contract = _contracts.filter((c) => c.id == account.parentId)[0];

      return {
        ...account,
        parentAccount: contract,
        accounts: subs,
      };
    });
    return result;
  }
  if (level == 'sub') {
    const result = _subs.map((sub) => {
      const account = _accounts.filter((a) => a.id == sub.parentId)[0];
      return {
        ...sub,
        parentAccount: account,
      };
    });

    return result;
  } else {
    const result = _contracts.map((contract) => {
      const accounts = _accounts
        .filter((a) => a.parentId == contract.id)
        .map((account) => {
          const subs = _subs
            .filter((sa) => sa.parentId == account.id)
            .map((sub) => {
              return {
                ...sub,
                parentAccount: account,
              };
            });

          return {
            ...account,
            parentAccount: contract,
            accounts: subs,
          };
        });
      return {
        ...contract,
        accounts,
      };
    });
    return result;
  }
}
