/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from "@ngx-formly/core";
import { LookupAutocompleteComponent } from "./lookup-autocomplete";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AddressVerificationModule, AutocompleteSelectionModule, EmployeeSearchModule, ManagerSearchModule } from '@autobot/shared'
import { ManagerSearchFormlyComponent } from './manager-search-formly';
import { EmployeeSearchFormlyComponent } from './employee-search-formly';
import { AddressVerificationFormlyComponent } from './address-verification-formly';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PhysicalTherapyCheckboxComponent } from '../formly-physical-therapy-checkbox/physical-therapy.checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [LookupAutocompleteComponent, ManagerSearchFormlyComponent,EmployeeSearchFormlyComponent,AddressVerificationFormlyComponent, PhysicalTherapyCheckboxComponent],
  imports: [
    CommonModule, FormlyModule.forChild({
      types: [{
        name: 'lookup',
        component: LookupAutocompleteComponent,
        // wrappers: ['form-field'],
      },
      {
        name: 'manager-search',
        component: ManagerSearchFormlyComponent
      },
      {
        name: 'employee-search',
        component: EmployeeSearchFormlyComponent
      },
      {
        name: 'smartystreet-search',
        component: AddressVerificationFormlyComponent
      },
      {
        name: 'physical-therapy',
        component: PhysicalTherapyCheckboxComponent
      }
      ],
    }), MatAutocompleteModule, MatCheckboxModule, ReactiveFormsModule, MatInputModule, MatIconModule, MatButtonModule, AutocompleteSelectionModule, ManagerSearchModule,EmployeeSearchModule,AddressVerificationModule, MatTooltipModule
  ],
  exports: [
    LookupAutocompleteComponent
  ]
})
export class FormlyLookupAutocompleteModule { }
