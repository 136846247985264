import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-material-textarea-autosize',
  template: `
    <textarea
      matInput
      [id]="id"
      [formControl]="formControl"
      [errorStateMatcher]="errorStateMatcher"
      [cols]="to.cols"
      [rows]="to.rows || 3"
      [placeholder]="to.placeholder ?? ''"
      [formlyAttributes]="field"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="3"
    ></textarea>
  `,
})
export class MaterialTextareaAutosizeComponent extends FieldType<FieldTypeConfig> {
  @ViewChild(MatInput)
  formFieldControl!: MatInput;
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;
  constructor(private _ngZone: NgZone) {
    super();
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      this.autosize.resizeToFitContent(true);
    });
  }
}

export const textAreaAutoResizeType = {
  name: 'textarea-auto-resize',
  component: MaterialTextareaAutosizeComponent,
  wrappers: ['form-field'],
};
