import {

  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { LookupService } from '../../services/lookup.service';

@Component({
  selector: 'formly-checkbox-type',
  template: `
      <div
      class="custom-checkbox-wrapper"
      [ngClass]="{
        'required-field': props?.required,
        'disabled-field': formControl?.disabled,
        'valid-field': formControl?.valid
      }"
      matTooltip="{{ props?.tooltip?.text || '' }}" 
      [matTooltipDisabled]="!(formControl?.disabled && !formControl?.value)" 
    >
      <mat-checkbox
        [formControl]="formControl || null"
        [formlyAttributes]="field || {}"
        [disabled]="formControl?.disabled"
      >
        {{ props?.label }}
      </mat-checkbox>
    </div>
    `,
  styles: [
    `
        .custom-checkbox-wrapper {
          display: flex;
          align-items: center;
        }
      `,
  ],
})
export class PhysicalTherapyCheckboxComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit {
  lookupSvc = inject(LookupService);
  isPhysicalTherapyApplicable: boolean = false;
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.updateControlStatus();
  }

  updateControlStatus() {
    const lookupData = this.lookupSvc.getPhysicalTherapyLookupData();
    this.updateControlState(lookupData);
  }

  private updateControlState(lookupData: any): void {
    if (lookupData?.accountProps) {
      const stateToCheck = lookupData?.stateOfJurisdiction;
      const accountProps = lookupData?.accountProps;
      const stateEligibilityList = accountProps?.stateEligibility;
      if (accountProps?.isPhysicalTherapyEnable) {
        this.isPhysicalTherapyApplicable = stateEligibilityList?.some(
          (state) => state.stateName === stateToCheck
        );

        const physicalTherapyControl = this.form.get('followup.medicalAssessment.dispositionReview.includePhysicalTherapy');

        if (physicalTherapyControl) {
          if (this.isPhysicalTherapyApplicable) {
            physicalTherapyControl.enable({ emitEvent: false });
          } else {
            physicalTherapyControl.setValue('');
            physicalTherapyControl.disable({ emitEvent: false });
          }

          // Ensure control validity is updated
          physicalTherapyControl.updateValueAndValidity({ emitEvent: false });
        }
      }
    }
  }
}
