import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ControlFocusService {

  constructor() {}

  focusFirstEnabledElement(element: ElementRef) {
    setTimeout(() => {
      const allFields = element.nativeElement.querySelectorAll('input:not([disabled]), select:not([disabled]), textarea:not([disabled]), mat-select:not([disabled])');
      
    const firstEnabledElement = allFields[0] as HTMLElement;

    if (firstEnabledElement) {
      firstEnabledElement.focus();
    }
  }, 300); 
  }
}
